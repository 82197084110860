<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="creditReturnPayHistoryPopup"
      header="미수정산내역"
      allowDragging="true"
      showCloseIcon="true"
      width="1180"
      height="800"
      :animationSettings="{ effect: 'None' }"
      isModal="true"
      @close="onCreditReturnPayHistoryPopupClosed"
    >
      <div class="window creditReturnPayHistoryPopup">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">미수일자 : {{ payDate }}</div>
                  </li>
                  <li class="field">
                    <div class="title">매장 : {{ storeName }}</div>
                  </li>
                  <li class="field">
                    <div class="title">성명 : {{ visitName }}</div>
                  </li>
                  <li class="field">
                    <div class="title">
                      미수금액 : {{ numberWithCommas(payAmt) }}
                    </div>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    :ignore="isPopupOpened"
                    @click.native="onCreditReturnPayHistoryViewClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">미수정산내역</div>
                      <div class="header-caption">
                        [{{
                          numberWithCommas(creditReturnPayHistoryListLength)
                        }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="save keyColor">
                          <erp-button
                            button-div="SAVE"
                            :is-shortcut-button="true"
                            @click.native="saveButtonClicked"
                          >
                            저장
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="creditReturnPayHistoryGrid"
                        :allowResizing="true"
                        :dataSource="creditReturnPayHistoryList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="creditReturnPayHistoryGridColumn"
                        :provides="grid"
                        :allowGrouping="false"
                        :aggregates="creditReturnPayHistoryGridAggregates"
                        :editSettings="creditReturnPayHistoryGridEditSettings"
                        @queryCellInfo="queryCellInfo"
                        @actionComplete="
                          creditReturnPayHistoryGridActionComplete
                        "
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeCreditReturnPayHistoryPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <pay-cancel-popup
      v-if="isPayCancelPopupOpen"
      ref="payCancelPopup"
      @popupClosed="payCancelPopupClosed"
    ></pay-cancel-popup>
    <re-issue-receipt-popup
      v-if="isReIssueReceiptPopupOpen"
      ref="reIssueReceiptPopup"
      @popupClosed="reIssueReceiptPopupClosed"
    ></re-issue-receipt-popup>
    <deposit-use-receipt-popup
      v-if="isDepositUseReceiptPopupOpen"
      ref="depositUseReceiptPopup"
      @popupClosed="depositUseReceiptPopupClosed"
    />
  </div>
</template>

<script>
import Vue from "vue";
import commonMixin from "@/views/layout/mixin/commonMixin";

import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  Edit,
} from "@syncfusion/ej2-vue-grids";
import payCancelPopup from "@/components/popups/PayCancelPopup";
import reIssueReceiptPopup from "@/components/popups/ReIssueReceiptPopup";
import depositUseReceiptPopup from "./DepositUseReceiptPopup";
import ErpButton from "@/components/button/ErpButton.vue";

import {
  commonCodesGetComName,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import { getTodayNavigationDate, DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
const PAY_INFO_GRID_MENU_CANCEL = "cancel";
const PAY_INFO_GRID_MENU_REPUBLIC = "republic";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "CreditReturnPayHistoryPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    payCancelPopup,
    reIssueReceiptPopup,
    depositUseReceiptPopup,
    ErpButton,
  },
  async created() {
    this.bsnDate = await getTodayNavigationDate(DATE_FORMAT_YYYY_MM_DD);
  },
  data() {
    return {
      isReload: false,
      creditReturnPayHistoryListLength: 0,
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, Edit],
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      creditReturnPayHistoryGridEditSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
      creditReturnPayHistoryGridColumn: [
        {
          field: "returnId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "returnDate",
          headerText: "회입일자",
          width: 90,
          type: "string",
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "payDiv",
          headerText: "정산구분",
          isCommonCodeField: true,
          groupCode: "PAY_DIV",
          width: 90,
          type: "string",
          allowEditing: false,
        },
        {
          field: "delStatus",
          headerText: "상태",
          width: 60,
          type: "string",
          allowEditing: false,
        },
        {
          field: "purcName",
          headerText: "카드사",
          width: 90,
          type: "string",
          allowEditing: false,
        },
        {
          field: "approvalDiv",
          headerText: "승인상태",
          isCommonCodeField: true,
          groupCode: "APPROVAL_DIV",
          width: 90,
          type: "string",
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "approvalNo",
          headerText: "승인번호",
          width: 90,
          type: "string",
          allowEditing: false,
        },
        {
          field: "cardNo",
          headerText: "카드번호",
          width: 140,
          type: "string",
          allowEditing: false,
        },
        {
          field: "returnAmt",
          headerText: "정산금액",
          textAlign: "right",
          width: 90,
          type: "number",
          format: "N",
          allowEditing: false,
        },
        {
          field: "insertName",
          headerText: "정산자",
          width: 80,
          type: "string",
          allowEditing: false,
        },
        {
          field: "insertDt",
          headerText: "정산일시",
          textAlign: "center",
          width: 130,
          type: "string",
          allowEditing: false,
        },
        {
          field: "dropdownMenu",
          headerText: "",
          width: 24,
          template: () => {
            return {
              template: {
                extends: Vue.component("payInfoGridDropdownTemplate", {
                  template:
                    "<div class='dropdownbutton'><ejs-dropdownbutton :items='meunItems' :select='onMenuSelect'></ejs-dropdownbutton></div>",
                  props: ["payInfoGridMenuSelectedCallback"],
                  data() {
                    return {
                      meunItems: [],
                      data: {
                        data: {},
                      },
                    };
                  },
                  mounted() {
                    if (this.data.payDiv === "CASH" && !this.data.approvalDiv) {
                      this.meunItems = this.data.delFlag
                        ? [
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ]
                        : [
                            {
                              text: "정산취소",
                              value: PAY_INFO_GRID_MENU_CANCEL,
                            },
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ];
                    } else if (this.data.payDiv === "DEPOSIT") {
                      this.meunItems = this.data.delFlag
                        ? [
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ]
                        : [
                            {
                              text: "정산취소",
                              value: PAY_INFO_GRID_MENU_CANCEL,
                            },
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ];
                    } else {
                      this.meunItems = this.data.delFlag
                        ? [
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ]
                        : [
                            {
                              text: "정산취소",
                              value: PAY_INFO_GRID_MENU_CANCEL,
                            },
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ];
                    }
                  },
                  methods: {
                    onMenuSelect(args) {
                      let eventParam = {
                        menu: args.item.value,
                        data: this.data,
                      };
                      this.payInfoGridMenuSelectedCallback(eventParam);
                      // this.$EventBus.$emit('payInfoGridMenuSelected', eventParam);
                    },
                  },
                }),
                propsData: {
                  payInfoGridMenuSelectedCallback: this
                    .onPayInfoGridMenuSelected,
                },
              },
            };
          },
          type: "string",
          allowFiltering: false,
          allowEditing: false,
        },
        {
          field: "remarks",
          headerText: "비고",
          textAlign: "left",
          width: 150,
          type: "string",
          allowEditing: true,
        },
        {
          field: "trnscDiv",
          headerText: "거래구분",
          isCommonCodeField: true,
          groupCode: "TRNSC_DIV",
          width: 90,
          type: "string",
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "closeDivFlag",
          headerText: "마감",
          textAlign: "center",
          width: 90,
          type: "boolean",
          displayAsCheckBox: true,
          allowEditing: false,
        },
        {
          field: "delReason",
          headerText: "취소사유",
          width: 300,
          type: "string",
          allowEditing: false,
        },
      ],
      creditReturnPayHistoryGridAggregates: [
        {
          columns: [
            {
              field: "returnAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.creditReturnPayHistoryReturnAmtAggregate,
            },
          ],
        },
      ],
      sumCaptionTemplate() {
        return {
          template: Vue.component("sumCaptionTemplate", {
            template: `<span v-html='data.Custom'></span>`, // 폰트굵기 bold css지정 필요
            data() {
              return { data: {} };
            },
          }),
        };
      },
      bsnDate: null,
      payId: null,
      payDate: null,
      storeCode: null,
      storeName: null,
      visitId: null,
      visitName: null,
      payAmt: null,
      creditReturnPayHistoryList: [],
      isFilteredApprovalDiv: false,
      isPayCancelPopupOpen: false,
      isReIssueReceiptPopupOpen: false,
      isDepositUseReceiptPopupOpen: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isPayCancelPopupOpen ||
        this.isReIssueReceiptPopupOpen ||
        this.isDepositUseReceiptPopupOpen
      );
    },
  },
  methods: {
    numberWithCommas,
    creditReturnPayHistoryReturnAmtAggregate(obj) {
      const objResult = obj.length ? obj : obj.result;
      const result = objResult.reduce((acc, cur) => {
        if (!cur.delFlag) {
          acc += cur.returnAmt;
        }
        return acc;
      }, 0);
      return `${numberWithCommas(result)}`;
    },
    queryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;

      if (field === "returnAmt") {
        if (data.delFlag) {
          cell.style.color = commonCodesGetColorValue("APPROVAL_DIV", "CANCEL");
        } else {
          cell.style.color = commonCodesGetColorValue("APPROVAL_DIV", "OK");
        }

        if (data.delFlag) {
          cell.style.setProperty("text-decoration", "line-through");
        }
      } else if (field === "approvalDiv") {
        cell.style.color = commonCodesGetColorValue(
          "APPROVAL_DIV",
          data.approvalDiv
        );
      } else if (field === "delStatus") {
        if (data.delFlag) {
          cell.style.color = commonCodesGetColorValue("APPROVAL_DIV", "CANCEL");
        } else {
          cell.style.color = commonCodesGetColorValue("APPROVAL_DIV", "OK");
        }
      } else if (field === "remarks") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    async showCreditReturnPayHistoryPopup(popupData) {
      this.payId = popupData.payId;
      this.payDate = popupData.payDate;
      this.storeCode = popupData.storeCode;
      this.visitId = popupData.visitId;
      this.visitName = popupData.visitName;
      this.payAmt = popupData.payAmt;
      this.storeName = commonCodesGetComName("STORE_CODE", this.storeCode);

      await this.onCreditReturnPayHistoryViewClicked();
    },
    onCreditReturnPayHistoryPopupClosed() {
      this.$emit("popupClosed", { isReload: this.isReload });
    },
    closeCreditReturnPayHistoryPopup() {
      this.$refs.creditReturnPayHistoryPopup.hide();
    },
    async onCreditReturnPayHistoryViewClicked() {
      this.creditReturnPayHistoryList = (
        await GolfErpAPI.fetchCreditReturnPayHistory(this.payId)
      ).map((data) => ({
        ...data,
        delStatus: data.delFlag ? "취소" : "정상",
      }));
      this.creditReturnPayHistoryListLength = this.creditReturnPayHistoryList.length;
    },
    onPayInfoGridMenuSelected(args) {
      if (args.menu === PAY_INFO_GRID_MENU_CANCEL) {
        if (this.commonMixinIsButtonDisableByAuth("creditReturnReg")) {
          return;
        }

        if (args.data.closeDivFlag) {
          this.errorToast(
            "일마감 처리된 정보 입니다. 정산 취소 할 수 없습니다"
          );
          return;
        }

        if (args.data.delFlag) {
          this.errorToast("이미 취소된 데이터입니다.");
          return;
        }

        this.isPayCancelPopupOpen = true;

        let popupData = {
          salesDiv: "CREDIT",
          // 미수회입내역에서 정산 취소시 chkinId와 visitId는 체크하지 않으므로 0으로 셋팅.
          chkinId: 0,
          visitId: 0,
          teamGrpFlag: false,
          payId: args.data.payId,
          payDate: args.data.returnDate,
          cardId: args.data.cardId,
          cashId: args.data.cashId,
          payDiv: args.data.payDiv,
          bsnDate: this.bsnDate,
          payAmt: args.data.returnAmt,
          visitName: this.visitName,
          storeCode: args.data.storeCode,
          returnId: args.data.returnId,
          useId: args.data.useId,
          depositId: args.data.depositId,
          isOnlyPayDataCancel:
            args.data.approvalDiv !== "OK" && args.data.approvalDiv !== "T_OK",
          isPayCash: !!args.data.cashId,
        };

        this.$nextTick(() => {
          this.$refs.payCancelPopup.showPayCancelPopup(popupData);
        });
      } else if (args.menu === PAY_INFO_GRID_MENU_REPUBLIC) {
        if (this.commonMixinIsButtonDisableByAuth("creditReturnGet")) {
          return;
        }

        // if (args.data.approvalDiv === "CANCEL") {
        //   this.errorToast("정산취소된 영수증입니다");
        //   return;
        // }

        if (
          args.data.payDiv === "DEPOSIT" ||
          args.data.payDiv === "PRECARD" ||
          args.data.payDiv === "GIFT"
        ) {
          this.isDepositUseReceiptPopupOpen = true;
          this.$nextTick(() => {
            this.$refs.depositUseReceiptPopup.showDepositUseReceiptPopup(
              args.data.useId
            );
          });
        } else {
          this.isReIssueReceiptPopupOpen = true;
          this.$nextTick(() => {
            this.$refs.reIssueReceiptPopup.showReIssueReceiptPopup({
              salesDiv: "CREDIT",
              payId:
                args.data.payDiv === "CARD"
                  ? args.data.cardId
                  : args.data.returnId,
              payDiv: args.data.payDiv,
            });
          });
        }
      }
    },
    payCancelPopupClosed: function (args) {
      this.isPayCancelPopupOpen = false;

      if (args !== undefined && args.isReload) {
        this.isReload = true;
        // 상세 조회
        this.onCreditReturnPayHistoryViewClicked();
      }
    },
    reIssueReceiptPopupClosed() {
      this.isReIssueReceiptPopupOpen = false;
    },
    creditReturnPayHistoryGridActionComplete(args) {
      if (args.requestType === "filtering" && args.action === "clearFilter") {
        this.creditReturnPayHistoryListLength = this.creditReturnPayHistoryList.length;
      } else if (args.requestType === "filtering") {
        this.creditReturnPayHistoryListLength = this.$refs.creditReturnPayHistoryGrid.getFilteredRecords().length;
      } else if (args.requestType === "refresh") {
        if (!this.isFilteredApprovalDiv) {
          this.isFilteredApprovalDiv = true;
          this.$refs.creditReturnPayHistoryGrid.filterByColumn(
            "delStatus",
            "equal",
            "정상"
          );
        }
      }
    },
    depositUseReceiptPopupClosed() {
      this.isDepositUseReceiptPopupOpen = false;
    },
    async saveButtonClicked() {
      const { changedRecords } = this.$refs.creditReturnPayHistoryGrid.patchBatchChanges();

      if (!(changedRecords.length > 0)) {
        this.infoToast(this.$t("main.popupMessage.noDataToSave"));
        return;
      }

      const saveData = changedRecords.map(record => ({
        ...record,
        id: record.returnId,
      }));

      await GolfErpAPI.patchTgPayCreditReturns(saveData);

      await this.onCreditReturnPayHistoryViewClicked();
    }
  },
};
</script>
