import request from "@/utils/request";

/**
 * @return Promise
 */
export function getTableUseStore(tableUseFlag, bsnDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/store-table/table-use-store",
    method: "get",
    params: {
      tableUseFlag,
      bsnDate,
    },
  });
}

/**
 * @return Promise
 */
export function getStoreTable(storeCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/store-table/table-list",
    method: "get",
    params: {
      storeCode: storeCode,
    },
  });
}

/**
 * @return Promise
 */
export function postStoreTable(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/store-table/table-list",
    method: "post",
    data,
  });
}

/**
 * @return Promise
 */
export function putStoreTable(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/store-table/table-list",
    method: "put",
    data,
  });
}

/**
 * @return Promise
 */
export function delStoreTableAll(storeCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/store-table/table-list",
    method: "delete",
    params: {
      storeCode: storeCode,
    },
  });
}
